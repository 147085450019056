import { take, shuffle, uniqBy, differenceBy } from "lodash/fp";
import QUESTIONS_FROM_FILE from "db/practice.json";
import { settings } from "config";

const safeStorage = (key: string, fallback: any = null): any => {
  const stored = localStorage.getItem(key);

  if (!stored) {
    return fallback;
  }

  try {
    return JSON.parse(stored);
  } catch (error) {
    console.error(error);
    return fallback;
  }
};

export const getPaidUnseenQuestions = (): Question[] =>
  safeStorage("UNSEEN_QUESTIONS", []);

export const getTrialUnseenQuestions = (): Question[] =>
  safeStorage("UNSEEN_QUESTIONS_TRIAL", []);

export const getUnseenQuestions = (): Question[] =>
  safeStorage(getUnseenKey(), []);

export const getCorrectQuestions = (): Question[] =>
  safeStorage("CORRECT_QUESTIONS", []);

export const getIncorrectQuestions = (): Question[] =>
  safeStorage("INCORRECT_QUESTIONS", []);

const filteredQuestions = (questions: Question[]) => {
  const correctQuestions = getCorrectQuestions();
  const incorrectQuestions = getIncorrectQuestions();
  const parsed = questions.filter(
    ({ statement }) =>
      !correctQuestions.some((x) => x.statement === statement) &&
      !incorrectQuestions.some((x) => x.statement === statement)
  );

  return parsed;
};

export const reloadUnseenQuestions = () => {
  const rawQuestions: { updatedAt: number; data: any[] } = localStorage.getItem(
    "RAW_QUESTIONS"
  )
    ? JSON.parse(localStorage.getItem("RAW_QUESTIONS")!)
    : QUESTIONS_FROM_FILE;

  const parsed = filteredQuestions(rawQuestions.data);

  localStorage.setItem(
    "UNSEEN_QUESTIONS_TRIAL",
    JSON.stringify(
      take(
        settings.TRIAL_TOTAL_UNSEEN,
        shuffle(parsed.filter(({ forTrial }) => forTrial))
      )
    )
  );
  localStorage.setItem("UNSEEN_QUESTIONS", JSON.stringify(parsed));
};

export const updateRawQuestions = (newQuestions: {
  updatedAt: number;
  data: any[];
}) => {
  const rawQuestions: { updatedAt: number; data: any[] } | null =
    localStorage.getItem("RAW_QUESTIONS")
      ? JSON.parse(localStorage.getItem("RAW_QUESTIONS")!)
      : null;

  if (!rawQuestions || newQuestions.updatedAt !== rawQuestions.updatedAt) {
    const parsed = filteredQuestions(newQuestions.data);

    if (parsed.length > 0) {
      localStorage.setItem("UNSEEN_QUESTIONS", JSON.stringify(parsed));
      localStorage.setItem("RAW_QUESTIONS", JSON.stringify(newQuestions));
    }
  }
};

if (!localStorage.getItem("RAW_QUESTIONS")) {
  localStorage.setItem("RAW_QUESTIONS", JSON.stringify(QUESTIONS_FROM_FILE));
}

if (!localStorage.getItem("UNSEEN_QUESTIONS")) {
  reloadUnseenQuestions();
}

export type Question = {
  statement: string;
  correctAnswer: string;
  options: string[];
  forTrial: boolean;
};

const getUnseenKey = (): string => {
  const isPaidMember = () => false; // TODO: fix
  if (isPaidMember()) {
    return "UNSEEN_QUESTIONS";
  } else {
    return "UNSEEN_QUESTIONS_TRIAL";
  }
};

const getRandomArbitrary = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

const getTimesPlayed = (): number => safeStorage("TIMES_PLAYED") || 0;

export const getQuestions = (
  total: number,
  bucket: "unseen" | "incorrect" = "unseen"
): Question[] => {
  if (bucket === "incorrect") {
    return take(total, shuffle(getIncorrectQuestions()));
  }

  const timesPlayed = getTimesPlayed();
  const shouldIncludePreviousIncorrectAnswers =
    timesPlayed > 2 && timesPlayed % 2 !== 0;

  const initialQuestions: Question[] = shouldIncludePreviousIncorrectAnswers
    ? take(
        getRandomArbitrary(total * 0.1, total * 0.2),
        shuffle(getIncorrectQuestions())
      )
    : [];

  return [
    ...initialQuestions,
    ...take(total - initialQuestions.length, shuffle(getUnseenQuestions())),
  ];
};

export const addIncorrectQuestions = (questions: Question[]) => {
  const newIncorrectQuestions = uniqBy("statement", [
    ...getIncorrectQuestions(),
    ...questions,
  ]);

  localStorage.setItem(
    "INCORRECT_QUESTIONS",
    JSON.stringify(newIncorrectQuestions)
  );
};

export const addCorrectQuestions = (questions: Question[]) => {
  const newCorrectQuestions = uniqBy("statement", [
    ...getCorrectQuestions(),
    ...questions,
  ]);

  localStorage.setItem(
    "CORRECT_QUESTIONS",
    JSON.stringify(newCorrectQuestions)
  );
};

export const removeFrom = (
  bucket: "unseen" | "correct" | "incorrect",
  questions: Question[]
) => {
  const storedQuestions =
    bucket === "unseen"
      ? getUnseenQuestions()
      : bucket === "correct"
      ? getCorrectQuestions()
      : getIncorrectQuestions();
  const newQuestions = differenceBy("statement", storedQuestions, questions);
  const storeKey =
    bucket === "unseen"
      ? getUnseenKey()
      : bucket === "correct"
      ? "CORRECT_QUESTIONS"
      : "INCORRECT_QUESTIONS";

  localStorage.setItem(storeKey, JSON.stringify(newQuestions));
};
