import cc from "classcat";
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import { Link } from "react-router-dom";

type Props = {
  children: any;
  $type: "primary" | "secondary" | "tertiary";
  $size: "sm" | "md";
  $fluid?: boolean;
  $inverted?: boolean;
} & (
  | ({
      to: string;
    } & AnchorHTMLAttributes<HTMLAnchorElement>)
  | ButtonHTMLAttributes<HTMLButtonElement>
);

const Button = ({ $type, $size, $fluid, $inverted, ...props }: Props) => {
  const className = cc([
    "inline-block rounded-sm text-center",
    {
      // $type
      "bg-aqua text-white active:bg-opacity-90": $type === "primary",
      "border-2 border-aqua text-aqua active:opacity-90":
        $type === "secondary" && !$inverted,
      "border-2 border-white text-white active:opacity-90":
        $type === "secondary" && $inverted,
      "text-aqua active:opacity-90": $type === "tertiary",
      // $size
      "px-3 py-2 text-md": $size === "md",
      "px-2 py-2 text-sm": $size === "sm",
      // $fluid
      "w-full": $fluid,
    },
    props.className,
  ]);

  if ("to" in props) {
    return <Link {...props} className={className} />;
  } else {
    return <button {...props} className={className} />;
  }
};

Button.defaultProps = {
  $type: "primary",
  $size: "md",
};

export default Button;
