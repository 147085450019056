import Button from "components/Button";
import Container from "components/Container";
import GoBackButton from "components/GoBackButton";
import React from "react";
import { useHistory } from "react-router";

function SelectGameMode() {
  const history = useHistory();
  return (
    <div className="h-full flex flex-col  justify-center">
      <div className="w-full">
        <div className="p-3">
          <GoBackButton route="/" />
        </div>
      </div>
      <Container className="space-y-4 flex flex-col items-center justify-center flex-grow relative">
        <h1 className="text-center py-8 text-aqua text-lg uppercase">
          Choose the game mode you want to play
        </h1>
        <div className="p-2 w-full">
          <Button
            $fluid
            className="p-2 w-full h-20 rounded-full text-2xl"
            onClick={() => history.push("/practice/multiple-choice")}
          >
            Multiple Choice
          </Button>
        </div>
        <div className="p-2 w-full">
          <Button
            $fluid
            className="p-2 w-full h-20 rounded-full text-2xl"
            onClick={() => history.push("/practice/fill-the-gap")}
          >
            Fill in the Blank
          </Button>
        </div>
        <Button
          $type="tertiary"
          onClick={() => {
            history.goBack();
          }}
        >
          go back
        </Button>
      </Container>
    </div>
  );
}

export default SelectGameMode;
