import { useUser } from "components/AuthProvider";
import Button from "components/Button";
import { getIncorrectQuestions } from "storage/collection";

const IncorrectQuestionsBox = () => {
  const incorrectQuestions = getIncorrectQuestions();
  const user = useUser();

  return (
    <>
      {incorrectQuestions.length > 10 && (
        <>
          {/* Space */}
          <div className="m-12" />
          <div className="text-sm w-full bg-red-100 border border-red-300 p-2">
            You have accumulated
            <br />
            <span className="text-red-500">
              {incorrectQuestions.length} incorrect
            </span>
            <br />
            answers
            <hr className="my-4 border-white" />
            <Button
              to={
                user.status === "member"
                  ? incorrectQuestions.length >= 20
                    ? "/practice/fill-the-gap?mode=incorrect"
                    : "/practice/fill-the-gap/10?mode=incorrect"
                  : "/subscribe"
              }
              $size="sm"
            >
              Let's try them!
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default IncorrectQuestionsBox;
