import logo from "assets/logo.png";
import { capitalize } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router";

type Props = {
  $type: "english" | "prepositions";
  $withHEART?: boolean;
};

const Logo = ({ $type, $withHEART }: Props) => {
  const history = useHistory();
  const [clickCounter, setClickCounter] = useState(0);

  return (
    <div
      className="text-center m-auto"
      onClick={() => {
        const limit = process.env.NODE_ENV === "development" ? 0 : 5;

        if (clickCounter >= limit) {
          history.push("/admin");
        } else {
          setClickCounter((current) => current + 1);
        }
      }}
    >
      <img src={logo} alt="Logo" width="100" className="inline-block" />
      <h1 className="mt-2 text-aqua text-xl">
        {$withHEART && <strong className="block">H.E.A.R.T.</strong>}
        <span
          className="block"
          style={{
            fontSize: $type === "prepositions" ? "0.9em" : "0.8em",
          }}
        >
          Perfect your
        </span>
        <strong
          className="block"
          style={{
            fontSize: $type === "prepositions" ? "0.9em" : "1.25em",
          }}
        >
          {capitalize($type)}
        </strong>
      </h1>
    </div>
  );
};

Logo.defaultProps = {
  $type: "prepositions",
};

export default Logo;
