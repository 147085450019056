import Container from "components/Container";
import Button from "components/Button";
import { useModal, WithModal } from "components/Modal";
import { useState } from "react";
import useIsClient from "hooks/useIsClient";
import ServerError from "components/ServerError";
import { CONTACT_EMAIL } from "config";
import firebase from "firebase/app";

const BecomeAClient = () => {
  const modal = useModal();
  const [showSent, setShowSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  if (showSent) {
    return (
      <Container>
        <p>
          <strong>Thank you</strong>, your request has been sent. We'll follow
          up as soon as possible.
        </p>
        <p className="space-y-2 pt-2">
          <Button
            $fluid
            $type="secondary"
            onClick={() => {
              modal.close();
            }}
          >
            Close
          </Button>
        </p>
      </Container>
    );
  }

  return (
    <Container>
      <p>
        $30 per 25 minute session or $200 for a package of 8 private sessions
        with Hayley.
      </p>

      {error && <ServerError>{error}</ServerError>}

      <p className="space-y-2 pt-2">
        <Button
          $fluid
          onClick={async () => {
            setLoading(true);
            const { currentUser } = firebase.app().auth();

            try {
              if (
                !currentUser ||
                !currentUser.email ||
                !currentUser.displayName
              ) {
                throw new Error("Missing user info");
              }

              await firebase
                .app()
                .firestore()
                .doc(`futureClients/${currentUser.email}`)
                .set({
                  name: currentUser.displayName,
                  date: new Date(),
                  timestamp: Date.now(),
                });

              setShowSent(true);
            } catch (err) {
              console.log(err);
              setError(
                `We couldn't communite with our servers. Please try again or contact us at ${CONTACT_EMAIL}`
              );
            }

            setLoading(false);
          }}
          disabled={loading}
        >
          I'm interested
        </Button>
        <Button
          $fluid
          $type="secondary"
          onClick={() => {
            modal.close();
          }}
        >
          Maybe another time
        </Button>
      </p>
    </Container>
  );
};

export default BecomeAClient;

export const BecomeAClientButton = WithModal(() => {
  const modal = useModal();
  const { loading, isClient } = useIsClient();

  if (loading || isClient) {
    return null;
  }

  return (
    <Button
      $fluid
      $type="tertiary"
      onClick={() => {
        modal.open(<BecomeAClient />);
      }}
    >
      Become a Client
    </Button>
  );
});
