export const settings = {
  TRIAL_TOTAL_UNSEEN: 200,
  TRIAL_AVAILABLE_BUNDLES: [10],
  TRIAL_PLAY_LIMIT: 20,
};

export const firebase = {
  apiKey: "AIzaSyCd0f2FFQfnwW57WkbMBVXrHOw-faHCk1k",
  authDomain: "heart-practice-your-english.firebaseapp.com",
  databaseURL: "https://heart-practice-your-english.firebaseio.com",
  projectId: "heart-practice-your-english",
  storageBucket: "heart-practice-your-english.appspot.com",
  messagingSenderId: "142156079611",
  appId: "1:142156079611:web:157be325b425bf85e62b5e",
  measurementId: "G-BR7TDKC03P",
};

const config = {
  settings,
  firebase,
};

export default config;

export const CONTACT_EMAIL = "heartadvancedenglish@gmail.com";

export const TIPS = [
  {
    text: "When you find a wrong answer, always read the CORRECT sentence out loud.",
    color: "#f44336",
  },
  {
    text: "Language is physical. Always repeat corrections OUT LOUD",
    color: "#9c27b0",
  },
  {
    text: "To improve your speaking skills, practice OUT LOUD.",
    color: "#3f51b5",
  },
  {
    text: "Speaking improvement suggestions will pop up occasionally.",
    color: "#009688",
  },
  {
    text: "1 or 2 quizzes a day is the best approach for noticeable improvement.",
    color: "#ff9800",
  },
];
