import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from "react";
import cc from "classcat";

const STYLES_COMMON = `w-full rounded-md border py-2 px-3 text-md`;

const STYLES_ENABLE = [
  STYLES_COMMON,
  "bg-white placeholder-gray-600 border-gray-600",
].join(" ");
const STYLES_DISABLE = [
  STYLES_COMMON,
  "bg-gray-200 text-gray-700 border-gray-500",
].join(" ");

const STYLE_WRONG_ANSWER = [
  STYLES_COMMON,
  "bg-red-200 border-red-500 border-opacity-100 text-red-700",
].join(" ");

const STYLE_CORRECT_ANSWER = [
  STYLES_COMMON,
  "bg-green-200 border-green-500 border-opacity-100 text-green-700",
].join(" ");
type Props =
  | {
      $type: "wrong_answer" | "correct_answer";
    } & DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >;

const InputField = forwardRef<any, any>(
  ({ $type, ...nativeAttrs }: Props, ref) => {
    return (
      <div>
        <input
          data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
          {...(nativeAttrs as any)}
          className={cc([
            {
              [STYLES_ENABLE]: !nativeAttrs.disabled && !$type,
              [STYLES_DISABLE]: nativeAttrs.disabled && !$type,
              [STYLE_WRONG_ANSWER]:
                nativeAttrs.disabled && $type === "wrong_answer",
              [STYLE_CORRECT_ANSWER]:
                nativeAttrs.disabled && $type === "correct_answer",
            },
          ])}
          ref={ref}
        />
      </div>
    );
  }
);

export const FormFieldWrapper = ({ children }: { children: any }) => (
  <div className="relative">{children}</div>
);

export default InputField;
