import {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";

const Context = createContext<{
  body: any;
  setBody: Dispatch<any>;
}>({
  body: null,
  setBody: () => undefined,
});

export const ModalProvider = ({ children }: any) => {
  const [body, setBody] = useState<any>(null);

  useEffect(() => {
    if (body) {
      document.querySelector("body")?.classList.add("overflow-hidden");
    } else {
      document.querySelector("body")?.classList.remove("overflow-hidden");
    }
  }, [body]);

  return (
    <Context.Provider value={{ body, setBody }}>
      {children}
      {body && <Modal>{body}</Modal>}
    </Context.Provider>
  );
};

type UseModal = {
  open: Dispatch<any>;
  close: () => void;
};

export const useModal = (): UseModal => {
  const { setBody } = useContext(Context);

  return {
    open: setBody,
    close: () => setBody(null),
  };
};

export const WithModal = (Component: FC) => () => {
  return (
    <ModalProvider>
      <Component />
    </ModalProvider>
  );
};

export const QuickModal = ({
  children,
}: {
  children: (modal: UseModal) => any;
}) => {
  const Component = () => {
    const modal = useModal();

    return children(modal);
  };

  return (
    <ModalProvider>
      <Component />
    </ModalProvider>
  );
};

const Modal = ({ children }: any) => {
  return (
    <div className="flex items-center justify-center fixed inset-0 bg-black bg-opacity-70">
      <div className="m-auto max-h-full overflow-y-auto p-4">
        <div className="w-full bg-white">{children}</div>
      </div>
    </div>
  );
};
