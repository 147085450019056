import * as Sentry from "@sentry/react";
import cc from "classcat";
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import Container from "components/Container";
import Login from "components/Login";
import Button from "components/Button";
import { Redirect } from "react-router";
import { useUser } from "components/AuthProvider";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { useEffect, useState } from "react";
import { CONTACT_EMAIL } from "config";

const Subscribe = () => {
  const user = useUser();

  if (user.isSignedIn && user.activeSubscription) {
    return <Redirect to="/" />;
  }

  return (
    <div className="safe-area-pt safe-area-pb">
      <Container>
        <Button to="/">
          <IonIcon icon={arrowBackOutline} className="block" />
        </Button>
        <IfFirebaseAuthed>
          {() => (
            <>
              <Plans />

              <hr className="border-gray-300 my-8" />

              <Button
                $fluid
                $type="secondary"
                $size="sm"
                to="/subscribe/clients"
              >
                Clients
              </Button>
            </>
          )}
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          {() => <Login defaultScreen="signup" />}
        </IfFirebaseUnAuthed>
      </Container>
    </div>
  );
};

export default Subscribe;

const Plans = () => {
  const [monthly, setMonthly] = useState(InAppPurchase2.get("monthly"));
  const [yearly, setYearly] = useState(InAppPurchase2.get("yearly"));
  const prices = [monthly, yearly].filter(
    (product) => product.canPurchase || product.state === "initiated"
  );

  if (prices.length < 1) {
    Sentry.captureMessage("No packages available", {
      extra: {
        prices,
        monthly,
        yearly,
      },
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (monthly.price == null) {
        console.log("--------**** Monthly price is null, retring ***--------");
        setMonthly(InAppPurchase2.get("monthly"));
      }
      if (yearly.price == null) {
        console.log("--------**** Yearly price is null, retring ***--------");
        setYearly(InAppPurchase2.get("yearly"));
      }
    }, 500);
  }, [monthly, yearly]);

  return (
    <Container>
      {prices.length > 0 ? (
        <>
          <ul>
            {prices.map(({ id, currency, price }) => (
              <li
                key={`product-${id}`}
                className={cc([
                  "p-4 my-4 text-center",
                  {
                    "bg-white shadow-lg": id === "yearly",
                  },
                ])}
              >
                <div className="text-xl">
                  {price && (
                    <span className="text-2xl">
                      {currency}
                      {price}
                    </span>
                  )}
                  <span className="opacity-50">
                    {price ? ` /` : ""}
                    {id}
                  </span>
                </div>

                <div className="pt-4">
                  <Button
                    $fluid
                    $type={id === "yearly" ? "primary" : "secondary"}
                    onClick={() => {
                      InAppPurchase2.order(id);
                    }}
                    disabled={prices.some(
                      (price) => price.state === "initiated"
                    )}
                  >
                    Subscribe
                  </Button>
                </div>
              </li>
            ))}
          </ul>

          <div className="text-center text-xs">
            By subscribing, you accept our
            <br />
            <a
              href="https://www.termsfeed.com/live/77df1f90-fb01-4e06-9f30-6be80f95db9e"
              target="_blank"
              rel="noreferrer"
              className="text-aqua underline"
            >
              Terms {`&`} Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://www.termsfeed.com/live/c7390429-bb18-4247-b267-797e3ef3a6d9"
              target="_blank"
              rel="noreferrer"
              className="text-aqua underline"
            >
              Privacy Policy
            </a>
          </div>
        </>
      ) : (
        <div className="text-center pt-4 space-y-4">
          <p>No packages available</p>

          <p className="text-xs">
            For more information, please contact support at {CONTACT_EMAIL}
          </p>
        </div>
      )}
    </Container>
  );
};
