import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";

const useIsClient = () => {
  // Note: do not render "error" because we might get "Missing or insufficient permissions" which does not apply for this case
  const [value, loading] = useDocument(
    firebase
      .firestore()
      .doc(`/hayleysClients/${firebase.auth().currentUser?.email}`)
  );

  return {
    loading,
    isClient: value?.data() != null,
  };
};

export default useIsClient;
