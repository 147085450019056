import Container from "components/Container";
import Button from "components/Button";
import { WithModal } from "components/Modal";
import { usePlay } from "storage/user.storage";
import { useEffect, useState } from "react";
import { IfFirebaseAuthed } from "@react-firebase/auth";
import { App } from "@capacitor/app";
import Logo from "components/Logo";
import { BecomeAClientButton } from "components/BecomeAClient";
import { useUser } from "components/AuthProvider";

const Home = () => {
  const user = useUser();
  const play = usePlay();
  const plays = play.plays();

  return (
    <div className="h-full flex flex-col safe-area-pt safe-area-pb">
      <Container className="w-full flex-grow text-center">
        {/* LOGO */}
        <Logo />
        {/* Space */}
        <div className="m-12" />
        <div className="py-6 bg-gray-100 space-y-2">
          <h2>Ready to practice?</h2>

          {plays.left >= 0 && (
            <div>
              You have {plays.left} quiz{plays.left > 1 ? "zes" : ""} left.
            </div>
          )}

          {(plays.left === -1 || plays.left > 0) && (
            <div className="pt-4">
              <Button to={plays.left === 0 ? "/subscribe" : "/select-mode"}>
                Let's start!
              </Button>
            </div>
          )}
        </div>

        {user && (
          <Button $fluid $type="secondary" to="/subscribe">
            Ready to join?
          </Button>
        )}

        <IfFirebaseAuthed>
          {() => (
            <>
              {/* Space */}
              <div className="m-12" />

              <Button to="/account" $fluid $type="secondary">
                My Account
              </Button>

              <BecomeAClientButton />
            </>
          )}
        </IfFirebaseAuthed>
      </Container>

      <AppVersion />
    </div>
  );
};

export default WithModal(Home);

const AppVersion = () => {
  const [version, setVersion] = useState<string | null>(null);

  useEffect(() => {
    App.getInfo()
      .then((result) => {
        setVersion(result.version);
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
  }, []);

  return (
    <div className="p-2 text-center text-xs text-gray-400">
      Version: {version || "N/A"}
    </div>
  );
};
