import { useUser } from "components/AuthProvider";
import getLeftTrialQuizzes from "utils/getLeftTrialQuizzes";
import { getTrialUnseenQuestions } from "./collection";
import * as Sentry from "@sentry/react";

type PlaysData = {
  left: number;
  total: number;
  history: number[];
  last: number | null;
};

const numberOr = (value: any, or: number) =>
  typeof value === "number" && !isNaN(value) ? value : or;

export const getLocalPlays = (isTrial: boolean) => {
  const DEFAULT = {
    left: isTrial ? getLeftTrialQuizzes(getTrialUnseenQuestions().length) : -1,
    total: 0,
    history: [],
    last: null,
  };

  const stored = window.localStorage.getItem("PLAYS");

  if (!stored) {
    window.localStorage.setItem("PLAYS", JSON.stringify(DEFAULT));
    return DEFAULT;
  }

  try {
    const parsed = JSON.parse(stored);
    const data = {
      left: numberOr(parsed.left, DEFAULT.left),
      total: numberOr(parsed.left, DEFAULT.total),
      history: parsed.history || DEFAULT.history,
      last: parsed.last || DEFAULT.last,
    };

    return data;
  } catch (error) {
    console.error("PLAYS PARSER ERROR", error);
    Sentry.captureMessage("PLAYS PARSER ERROR", {
      extra: {
        error,
      },
      level: Sentry.Severity.Error,
    });
    window.localStorage.setItem("PLAYS", JSON.stringify(DEFAULT));
    return DEFAULT;
  }
};

const setLocalPlays = (data: Partial<PlaysData>): void => {
  try {
    const storedData = JSON.parse(window.localStorage.getItem("PLAYS")!);

    window.localStorage.setItem(
      "PLAYS",
      JSON.stringify({
        ...storedData,
        ...data,
      })
    );
  } catch (e) {
    window.localStorage.setItem("PLAYS", JSON.stringify(data));
  }
};

export const usePlay = () => {
  const { status } = useUser();

  const plays = (): PlaysData => {
    switch (status) {
      case "anonymous": {
        return getLocalPlays(true);
      }
      case "non-member": {
        // TODO: Use Firestore
        return getLocalPlays(true);
      }
      case "member": {
        // TODO: Use Firestore
        return getLocalPlays(false);
      }
    }
  };

  const setPlays = (partialData: Partial<PlaysData>) =>
    setLocalPlays(partialData);

  const addPlay = () => {
    switch (status) {
      case "anonymous": {
        const currentPlays = plays();
        const timestamp = Date.now();

        setLocalPlays({
          left: currentPlays.left > 0 ? currentPlays.left - 1 : 0,
          total: currentPlays.total + 1,
          history: [...currentPlays.history, timestamp],
          last: timestamp,
        });

        break;
      }
      case "non-member": {
        // TODO: Store info in firestore
        const currentPlays = plays();
        const timestamp = Date.now();

        setLocalPlays({
          left: currentPlays.left > 0 ? currentPlays.left - 1 : 0,
          total: currentPlays.total + 1,
          history: [...currentPlays.history, timestamp],
          last: timestamp,
        });
        break;
      }
      case "member": {
        // TODO: Store info in firestore
        const currentPlays = plays();
        const timestamp = Date.now();

        setLocalPlays({
          left: -1,
          total: currentPlays.total + 1,
          history: [...currentPlays.history, timestamp],
          last: timestamp,
        });
        break;
      }
    }
  };

  return {
    status,
    plays,
    addPlay,
    setPlays,
  };
};
