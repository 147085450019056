import { IonIcon } from "@ionic/react";
import Container from "components/Container";
import {
  getPaidUnseenQuestions,
  getTrialUnseenQuestions,
  reloadUnseenQuestions,
} from "storage/collection";
import { getCorrectQuestions, getIncorrectQuestions } from "storage/collection";
import { useLayoutEffect, useState } from "react";
import Button from "components/Button";
import { arrowBackOutline } from "ionicons/icons";
import useInAppSubscription from "hooks/useInAppSubscription";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useUser } from "components/AuthProvider";
import { usePlay } from "storage/user.storage";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";

const Admin = () => {
  const subscription = useInAppSubscription();
  const [state, setState] = useState<null | {
    unseen: number;
    unseenTrial: number;
    correct: number;
    incorrect: number;
  }>(null);
  const user = useUser();
  const play = usePlay();

  const loadData = () => {
    setState({
      unseen: getPaidUnseenQuestions().length,
      unseenTrial: getTrialUnseenQuestions().length,
      correct: getCorrectQuestions().length,
      incorrect: getIncorrectQuestions().length,
    });
  };

  useLayoutEffect(() => {
    loadData();
  }, []);

  if (!state) {
    return null;
  }

  return (
    <div className="safe-area-pt safe-area-pb">
      <Container>
        <Button to="/">
          <IonIcon icon={arrowBackOutline} className="block" />
        </Button>
        <p className="text-red-500 my-10">
          NOTE: This is a temporary internal screen that we will use for
          Development ONLY purposes!
        </p>

        <p className="py-6">
          <p>
            <strong className="text-aqua">Unseen:</strong> {state.unseen}
          </p>
          <p>
            <strong className="text-aqua">Unseen for Trial:</strong>{" "}
            {state.unseenTrial}
          </p>
          <p>
            <strong className="text-aqua">Correct:</strong> {state.correct}
          </p>
          <p>
            <strong className="text-aqua">Incorrect:</strong> {state.incorrect}
          </p>
        </p>

        <Button
          onClick={() => {
            localStorage.clear();
            reloadUnseenQuestions();
            loadData();

            alert("App cache has been clear.");
          }}
        >
          Clear cache
        </Button>

        <div className="py-6">
          <h1 className="pb-2">In App Purchase info</h1>
          <div className="text-xs">
            <SyntaxHighlighter language="javascript" style={docco}>
              {JSON.stringify(
                {
                  applicationUsername: InAppPurchase2.getApplicationUsername(),
                },
                null,
                2
              )}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className="py-6">
          <h1 className="pb-2">User state</h1>
          <div className="text-xs">
            <SyntaxHighlighter language="javascript" style={docco}>
              {JSON.stringify(user, null, 2)}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className="py-6">
          <h1 className="pb-2">Play state</h1>
          <div className="text-xs">
            <SyntaxHighlighter language="javascript" style={docco}>
              {JSON.stringify(
                {
                  status: play.status,
                  plays: play.plays(),
                },
                null,
                2
              )}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className="py-6">
          <h1 className="pb-2">Subscription state</h1>
          <div className="text-xs">
            <SyntaxHighlighter language="javascript" style={docco}>
              {JSON.stringify(subscription.state, null, 2)}
            </SyntaxHighlighter>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Admin;
