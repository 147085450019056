import Container from "components/Container";
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { logout, useUser } from "components/AuthProvider";
import Button from "components/Button";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import firebase from "firebase/app";
import useSubscriptions from "hooks/useSubscriptions";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { capitalize } from "lodash";
import IncorrectQuestionsBox from "components/IncorrectQuestionsBox";
import { Redirect } from "react-router";
import { CONTACT_EMAIL } from "config";

dayjs.extend(calendar);

const Account = () => {
  return (
    <div className="safe-area-pt safe-area-pb">
      <Container>
        <Button to="/">
          <IonIcon icon={arrowBackOutline} className="block" />
        </Button>

        <div className="pt-6">
          <IfFirebaseAuthed>
            {({ user }) => <LoggedIn user={user} />}
          </IfFirebaseAuthed>

          <IfFirebaseUnAuthed>{() => <Anonymous />}</IfFirebaseUnAuthed>
        </div>
      </Container>
    </div>
  );
};

export default Account;

const LoggedIn = ({ user }: { user: firebase.User }) => {
  const { activeSubscription } = useUser();

  return (
    <div className="space-y-6">
      <p className="text-aqua">
        Welcome <strong>{user.displayName}</strong>
      </p>

      <div className="p-4 bg-white">
        {activeSubscription ? (
          <>
            <div>
              <strong>Active subscription:</strong>{" "}
              {[
                activeSubscription.price,
                capitalize(activeSubscription.title || activeSubscription.id),
                "subscription",
              ]
                .filter(Boolean)
                .join(" ")}
            </div>

            {activeSubscription.expiryDate && (
              <div className="pt-2">
                Expires {dayjs(activeSubscription.expiryDate).calendar()}
              </div>
            )}
          </>
        ) : (
          "No active subscription"
        )}
      </div>

      <IncorrectQuestionsBox />

      <Button
        $fluid
        $type="secondary"
        onClick={() => {
          InAppPurchase2.manageSubscriptions();
        }}
      >
        Manage Subscription
      </Button>

      <Button
        $fluid
        $type="secondary"
        onClick={() => {
          InAppPurchase2.manageBilling();
        }}
      >
        Manage Billing
      </Button>

      <Button
        $fluid
        $type="tertiary"
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

export const WebSubscription = () => {
  const { fetching, subscriptions } = useSubscriptions();

  if (fetching) {
    return <div />;
  }

  if (!subscriptions || subscriptions.length === 0) {
    return <div>No active subscription</div>;
  }

  // console.log("subscription", subscription);
  const subscription =
    subscriptions.find((sub) => ["trialing", "active"].includes(sub.status)) ||
    subscriptions[0];

  const plan = subscription.items?.[0]?.plan;

  if (!plan) {
    return <div className="text-red-500">Error loading subscription</div>;
  }

  const ends = dayjs(subscription.current_period_end.seconds * 1000);
  const wasCanceled = !!subscription.canceled_at;
  const canCancel = ["trialing", "active"].includes(subscription.status);

  return (
    <div className="text-center">
      <div className="pb-3">
        Your {plan.interval}ly subscription is{" "}
        <SubStatus status={subscription.status} /> and it{" "}
        {wasCanceled
          ? "was canceled"
          : dayjs().isBefore(ends)
          ? "ends"
          : "ended"}{" "}
        <strong>{ends.calendar()}</strong>
      </div>
      {canCancel && (
        <Button
          $fluid
          $type="tertiary"
          onClick={() => {
            alert(
              `Please contact support to cancel your subscription at ${CONTACT_EMAIL}.`
            );
          }}
        >
          Cancel subscription
        </Button>
      )}
      {!canCancel && (
        <Button $fluid to="/subscribe">
          Subscribe
        </Button>
      )}
    </div>
  );
};

const SubStatus = ({ status }: { status: string }) => {
  switch (status) {
    case "active": {
      return <strong className="text-aqua">Active</strong>;
    }
    case "trialing": {
      return <strong className="text-aqua">Active (trial period)</strong>;
    }
    case "canceled":
    case "incomplete":
    case "incomplete_expired":
    case "past_due": {
      return <strong className="text-red-500">Inactive</strong>;
    }
    case "unpaid": {
      return <strong className="text-red-500">Inactive (unpaid)</strong>;
    }
    default: {
      return null;
    }
  }
};

const Anonymous = () => <Redirect to="/" />;
