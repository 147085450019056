import isBrowser from "utils/isBrowser";
import { createContext, useContext, useEffect, useState } from "react";
import { IAPProduct, InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { useUser } from "components/AuthProvider";
import * as Sentry from "@sentry/react";
import { usePlay } from "storage/user.storage";

const debug = (msg: string) => console.debug(":: In App Purchase ::", msg);

const Context = createContext<any>(null);

export const InAppPurchaseProvider = ({ children }: { children: any }) => {
  const { setActiveSubscription } = useUser();
  const play = usePlay();
  const [state, setState] = useState<any>({});
  const [loading, setLoading] = useState<null | string>(null);
  const [subscribed, setSubscribed] = useState<null | IAPProduct>(null);

  useEffect(() => {
    debug("init (useEffect)");

    if (isBrowser()) {
      debug("browser mode, do nothing");
      return;
    }

    InAppPurchase2.register([
      {
        id: "monthly",
        type: InAppPurchase2.PAID_SUBSCRIPTION,
      },
      {
        id: "yearly",
        type: InAppPurchase2.PAID_SUBSCRIPTION,
      },
    ]);

    InAppPurchase2.error((error: any) => {
      console.debug(`InAppPurchase2 :: ERROR`, error);
      Sentry.captureException(error);
      alert(error.message || "Something went wrong.");
    });

    InAppPurchase2.when("product")
      .updated(() => {
        debug(`Product :: updated`);
        const monthly = InAppPurchase2.get("monthly");
        const yearly = InAppPurchase2.get("yearly");
        setState({ monthly, yearly });
        setLoading(null);
      })
      .owned((pkg: any) => {
        debug(`Product :: owned`);
        play.setPlays({
          left: -1,
        });
        setLoading(null);
        setSubscribed(pkg);
        setActiveSubscription(pkg);

        const monthly = InAppPurchase2.get("monthly");
        const yearly = InAppPurchase2.get("yearly");
        setState({ monthly, yearly });
      })
      .approved((pkg: any) => {
        debug(`Product :: approved`);
        setSubscribed(null);
        setActiveSubscription(null);
        setLoading("processing");
        pkg.finish();
        const monthly = InAppPurchase2.get("monthly");
        const yearly = InAppPurchase2.get("yearly");
        setState({ monthly, yearly });
      })
      .requested(() => {
        debug(`Product :: requested`);
        setSubscribed(null);
        setActiveSubscription(null);
        setLoading("subscribing");
        const monthly = InAppPurchase2.get("monthly");
        const yearly = InAppPurchase2.get("yearly");
        setState({ monthly, yearly });
      })
      .initiated(() => {
        debug(`Product :: initiated`);
        setSubscribed(null);
        setActiveSubscription(null);
        setLoading("subscribing");
        const monthly = InAppPurchase2.get("monthly");
        const yearly = InAppPurchase2.get("yearly");
        setState({ monthly, yearly });
      });

    InAppPurchase2.refresh();
    debug("refresh");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        subscribed,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useInAppSubscription = () => useContext(Context);

export default useInAppSubscription;
