import { TextField } from "@material-ui/core";
import { login, signup, forgotPassword } from "components/AuthProvider";
import Button from "components/Button";
import FormGroup from "components/FormGroup";
import { FormEvent, useState } from "react";

type Screen = "login" | "signup" | "forgot";

type Props = {
  defaultScreen: Screen;
  onDone?: () => void;
};

const Login = ({ defaultScreen, onDone }: Props) => {
  const [screen, setScreen] = useState<Screen>(defaultScreen);

  if (screen === "login") {
    return (
      <LoginForm
        goToSignup={() => setScreen("signup")}
        goToForgotPassword={() => setScreen("forgot")}
        onDone={onDone}
      />
    );
  }

  if (screen === "forgot") {
    return <ForgotPasswordForm goToLogin={() => setScreen("login")} />;
  }

  return <SignupForm goToLogin={() => setScreen("login")} onDone={onDone} />;
};

Login.defaultProps = {
  defaultScreen: "login",
};

export default Login;

type LoginFormProps = {
  goToSignup: () => void;
  goToForgotPassword: () => void;
  onDone?: () => void;
};

const LoginForm = ({
  goToSignup,
  goToForgotPassword,
  onDone,
}: LoginFormProps) => {
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="text-center">
      <h1 className="text-aqua">Hello there</h1>
      <form
        className="py-4"
        onSubmit={async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          const email = e.currentTarget.email.value;
          const password = e.currentTarget.password.value;

          try {
            await login(email, password);
            onDone?.();
          } catch (error: any) {
            setError(error.message);
          }
        }}
      >
        <FormGroup>
          <TextField
            className="w-full"
            name="email"
            label="Email"
            required
            type="email"
          />
          <TextField
            className="w-full"
            label="Password"
            required
            name="password"
            type="password"
          />
          {error && <div className="text-red-500">{error}</div>}
          <Button type="submit" $fluid className="mt-2">
            Login
          </Button>

          <Button
            onClick={() => {
              goToSignup();
            }}
            $fluid
            $type="tertiary"
          >
            Don't have an account? Signup
          </Button>
          <Button
            onClick={() => {
              goToForgotPassword();
            }}
            $fluid
            $type="tertiary"
          >
            Forgot my password
          </Button>
        </FormGroup>
      </form>
    </div>
  );
};

type SignupFormProps = {
  goToLogin: () => void;
  onDone?: () => void;
};

const SignupForm = ({ goToLogin, onDone }: SignupFormProps) => {
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="text-center">
      <h1 className="text-aqua">Ready to join?</h1>
      <form
        className="py-4"
        onSubmit={async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          const fullName = e.currentTarget.fullName.value;
          const email = e.currentTarget.email.value;
          const password = e.currentTarget.password.value;

          try {
            await signup(email, password, fullName);
            onDone?.();
          } catch (error: any) {
            setError(error.message);
          }
        }}
      >
        <FormGroup>
          <TextField
            className="w-full"
            name="fullName"
            label="Full Name"
            required
            type="text"
          />
          <TextField
            className="w-full"
            name="email"
            label="Email"
            required
            type="email"
          />
          <TextField
            className="w-full"
            label="Password"
            required
            name="password"
            type="password"
          />
          {error && <div className="text-red-500">{error}</div>}
          <Button type="submit" $fluid className="mt-2">
            Signup!
          </Button>

          <Button
            onClick={() => {
              goToLogin();
            }}
            $fluid
            $type="tertiary"
          >
            Already have an account? Login
          </Button>
        </FormGroup>
      </form>
    </div>
  );
};

type ForgotPasswordFormProps = {
  goToLogin: () => void;
};

const ForgotPasswordForm = ({ goToLogin }: ForgotPasswordFormProps) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="text-center">
      <h1 className="text-aqua">Forgot Password</h1>
      {sent ? (
        <div className="py-6">
          <p className="pb-8">
            Almost done! we have sent you an email containing a link to reset
            your password.
          </p>
          <Button
            onClick={() => {
              goToLogin();
            }}
            $fluid
            $type="tertiary"
          >
            Back to Login
          </Button>
        </div>
      ) : (
        <form
          className="py-4"
          onSubmit={async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const email = e.currentTarget.email.value;

            try {
              await forgotPassword(email);
              setSent(true);
            } catch (error: any) {
              setError(error.message);
            }
          }}
        >
          <FormGroup>
            <TextField
              className="w-full"
              name="email"
              label="Email"
              required
              type="email"
            />
            {error && <div className="text-red-500">{error}</div>}
            <Button type="submit" $fluid className="mt-2">
              Reset Password
            </Button>

            <Button
              onClick={() => {
                goToLogin();
              }}
              $fluid
              $type="tertiary"
            >
              Back to Login
            </Button>
          </FormGroup>
        </form>
      )}
    </div>
  );
};
