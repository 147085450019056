import * as Sentry from "@sentry/react";
import firebase from "firebase/app";
import cc from "classcat";
import reactStringReplace from "react-string-replace";
import useSound from "use-sound";
import { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import {
  addCorrectQuestions,
  addIncorrectQuestions,
  getQuestions,
  Question,
  removeFrom,
} from "storage/collection";
import Container from "components/Container";
import { shuffle } from "lodash/fp";
import starBurstLottie from "assets/lottie/26892-star-burst-animation.json";
import confettiFullScreen from "assets/lottie/69030-confetti-full-screen.json";
import trophyWinner from "assets/lottie/67230-trophy-winner.json";
import sadLook from "assets/lottie/59204-sad-look.json";
import thumbsUp from "assets/lottie/14595-thumbs-up.json";
import Lottie from "lottie-react";
import Button from "components/Button";
import { useModal, WithModal } from "components/Modal";
import Feedback from "components/Feedback";
import { usePlay } from "storage/user.storage";
import { useUser } from "components/AuthProvider";
import { ReactComponent as IconSound } from "../assets/icons/sound.svg";
import { ReactComponent as IconSoundMuted } from "../assets/icons/sound-muted.svg";
import { CONTACT_EMAIL, TIPS } from "config";
import Tip from "components/Tip";
import InputField from "components/InputField";
const errorSound = require("../assets/sounds/error.mp3");
const dingSound = require("../assets/sounds/ding.mp3");
const failSound = require("../assets/sounds/fail.mp3");
const winSound = require("../assets/sounds/win.wav");

type Answer = {
  question: Question;
  correct: boolean;
};

const getCorrectAnswers = (answers: Answer[]) =>
  answers.filter((a) => a.correct).map((a) => a.question);

const getIncorrectAnswers = (answers: Answer[]) =>
  answers.filter((a) => !a.correct).map((a) => a.question);

const getResults = (correctAnswers: Question[], limit: number) => {
  const correctAnswersPercentage = (correctAnswers.length * 100) / limit;
  const great = correctAnswersPercentage >= 80;
  const ok = correctAnswersPercentage < 80 && correctAnswersPercentage >= 50;
  const bad = !great && !ok;

  return {
    correctAnswersPercentage,
    status: {
      current: great ? "great" : ok ? "ok" : "bad",
      great,
      ok,
      bad,
    },
  };
};

const PlayGame = () => {
  const location = useLocation();
  const params = useParams<{ limit: string }>();
  const limit = parseInt(params.limit, 10);
  const history = useHistory();
  const mode = new URLSearchParams(location.search).get("mode") || "new";
  const { mode: gameMode } = useParams<{ mode: string }>();
  const [selected, setSelected] = useState<null | string>(null);
  const [evaluated, setEvaluated] = useState<null | { success: boolean }>(null);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [showStars, setShowStars] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const canPlaySoundJson = localStorage.getItem("canPlaySound");
  const [canPlaySound, setCanPlaySound] = useState<Boolean>(
    canPlaySoundJson ? JSON.parse(canPlaySoundJson) : true
  );

  const current = answers.length;
  const modal = useModal();
  const play = usePlay();
  const user = useUser();
  const [playErrorSound] = useSound(errorSound);
  const [playDingSound] = useSound(dingSound);
  const [playFailSound] = useSound(failSound);
  const [playWinSound] = useSound(winSound);
  const hasFinished = answers.length === limit;
  const [tip, setTip] = useState<null | { text: string; color: string }>(
    TIPS[Math.floor(Math.random() * TIPS.length)]
  );
  const fillRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLoadingQuestions(true);

    const newQuestions = getQuestions(
      limit,
      mode === "incorrect" ? "incorrect" : "unseen"
    );

    if (!newQuestions.length) {
      Sentry.captureMessage(`No more questions`, {
        extra: {
          userStatus: user.status,
          playsLeft: play.plays().left,
          current,
          limit,
          mode,
          newQuestions,
        },
      });
      setQuestions(newQuestions);
    } else {
      setQuestions(
        newQuestions.map((x) => ({
          ...x,
          options: shuffle(x.options),
        }))
      );
      setLoadingQuestions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  useEffect(() => {
    if (hasFinished) {
      const correctAnswers = getCorrectAnswers(answers);
      const incorrectAnswers = getIncorrectAnswers(answers);
      if (mode === "new") {
        if (correctAnswers.length > 0) {
          addCorrectQuestions(correctAnswers);
        }

        if (incorrectAnswers.length > 0) {
          addIncorrectQuestions(incorrectAnswers);
        }

        removeFrom(
          "unseen",
          answers.map((a) => a.question)
        );
      } else {
        removeFrom("incorrect", correctAnswers);
      }

      play.addPlay();

      const { status, correctAnswersPercentage } = getResults(
        correctAnswers,
        limit
      );

      if (status.great) {
        canPlaySound && playWinSound();
      } else if (status.bad) {
        canPlaySound && playFailSound();
      }

      firebase.analytics().logEvent("play_finished", {
        mode,
        status: status.current,
        correctAnswersPercentage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFinished]);

  if (loadingQuestions) {
    return <LoadingQuestions />;
  }

  if (user.status !== "member" && play.plays().left === 0) {
    Sentry.captureMessage("Cannot play", {
      extra: {
        userStatus: user.status,
        playsLeft: play.plays().left,
        limit,
        mode,
      },
    });

    return <Redirect to="/" />;
  }

  if (hasFinished) {
    return <Results answers={answers} limit={limit} />;
  }

  const data = questions[current];

  if (!data) {
    Sentry.captureException(new Error(`No data to play with`), {
      extra: {
        userStatus: user.status,
        playsLeft: play.plays().left,
        questions,
        current,
        limit,
        mode,
      },
    });

    // TODO: Better handling
    alert(
      `Unable to load the question. Please contact support at ${CONTACT_EMAIL}`
    );

    return <Redirect to="/" />;
  }

  const { statement, options, correctAnswer } = data;
  const onAnswer = (option: string) => {
    if (!evaluated) {
      const isCorrect = option === correctAnswer;
      setSelected(option);
      setEvaluated({
        success: isCorrect,
      });

      if (isCorrect) {
        console.log("--hola");
        setShowStars(true);
        canPlaySound && playDingSound();
      } else {
        canPlaySound && playErrorSound();
      }

      setShowNeedHelp(!isCorrect);
    }
  };

  return (
    <div className="flex flex-col h-full relative">
      {current === 3 && tip && (
        <Tip toggle={() => setTip(null)} bgColor={tip.color}>
          {tip.text}
        </Tip>
      )}
      <div className="flex items-center safe-area-pt">
        <Button
          $type="tertiary"
          $size="sm"
          onClick={() => {
            if (window.confirm("Are you sure you want to stop practicing?")) {
              firebase.analytics().logEvent("play_stopped", {
                question: {
                  index: current,
                  current: current + 1,
                  limit,
                },
              });
              history.push("/select-mode");
            }
          }}
        >
          Close
        </Button>
        <div className="flex-grow text-center">
          Question {current + 1} of {limit}
        </div>
        <div className="w-14" />
        <div
          className="mr-2"
          onClick={() => {
            setCanPlaySound(!canPlaySound);
            localStorage.setItem("canPlaySound", JSON.stringify(!canPlaySound));
          }}
        >
          {canPlaySound ? (
            <IconSound width={30} height={30} />
          ) : (
            <IconSoundMuted width={30} height={30} />
          )}
        </div>
      </div>

      <div className="flex-grow flex flex-col">
        {/* Statement */}
        <div className="relative flex-grow bg-gray-700 text-white flex items-center justify-center py-12 px-6">
          <div className="text-xl font-sans leading-10 text-center">
            {reactStringReplace(statement, "{BLANK}", (_match, index) =>
              evaluated ? (
                <strong
                  key={`phrase-${index}`}
                  className={cc([
                    "inline-block bg-white px-2 text-white border-4 border-green-500 shadow-lg animate-popAnswer -my-1 rounded-md bg-green-700 uppercase",
                  ])}
                >
                  {correctAnswer}
                </strong>
              ) : (
                <div
                  className="inline-block w-8 bg-white mx-1 -mb-1"
                  key={`phrase-${index}`}
                  style={{ height: 2 }}
                />
              )
            )}
          </div>
          {showNeedHelp && selected && (
            <div className="absolute left-0 bottom-0 w-full text-center text-sm p-2">
              <Button
                $type="secondary"
                $size="sm"
                $inverted
                onClick={() => {
                  modal.open(
                    <Feedback
                      $type="answer"
                      question={data}
                      answer={selected}
                    />
                  );
                }}
              >
                Feedback
              </Button>
            </div>
          )}
        </div>

        <div className="relative py-2">
          {/* Fill In */}
          {gameMode === "fill-the-gap" ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                fillRef.current &&
                  fillRef.current.value &&
                  onAnswer(fillRef.current.value.toLowerCase());
              }}
              className="mx-3"
            >
              <div className="mt-3">
                <InputField
                  ref={fillRef}
                  maxLength={10}
                  $type={
                    !evaluated
                      ? undefined
                      : !evaluated.success
                      ? "wrong_answer"
                      : "correct_answer"
                  }
                  disabled={evaluated}
                  placeHolder="Enter answer here"
                />
              </div>
              {!evaluated && (
                <Button $fluid className="mt-3 rounded">
                  Continue
                </Button>
              )}
            </form>
          ) : (
            <ul className="text-center">
              {/* Choices */}
              {options.map((option, i) => (
                <li key={`option-${i}`} className="inline-block p-2">
                  <button
                    className={cc([
                      "flex items-center justify-center border-2 p-3 rounded-md text-sm",
                      {
                        "border-aqua border-opacity-30 bg-white": !evaluated,
                        "bg-red-200 border-red-500 border-opacity-100 text-red-700":
                          evaluated &&
                          selected === option &&
                          !evaluated.success,
                        "bg-green-200 border-green-500 border-opacity-100 text-green-700":
                          evaluated && selected === option && evaluated.success,
                      },
                    ])}
                    onClick={() => {
                      onAnswer(option);
                    }}
                  >
                    <span
                      className={cc([
                        "block w-4 h-4 rounded-full border-2",
                        {
                          "border-aqua bg-white": !evaluated,
                          "border-red-500 bg-red-500":
                            evaluated &&
                            selected === option &&
                            !evaluated.success,
                          "border-green-500 bg-green-500":
                            evaluated &&
                            selected === option &&
                            evaluated.success,
                        },
                      ])}
                    />
                    <span className="block pl-2 leading-4 uppercase">
                      {option}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          )}
          {showStars && (
            <Lottie
              className="absolute -bottom-full left-1/2 transform -translate-x-1/2 w-full mb-5"
              animationData={starBurstLottie}
              loop={false}
            />
          )}
        </div>

        {/* CTA */}
        {((gameMode === "fill-the-gap" && evaluated) ||
          gameMode === "multiple-choice") && (
          <div className="p-3 safe-area-mb">
            <button
              className={cc([
                "z-2 relative w-full text-white p-3 rounded",
                {
                  "bg-gray-700": selected,
                  "bg-gray-400": !selected,
                  invisible: !evaluated,
                },
              ])}
              onClick={() => {
                if (evaluated) {
                  setEvaluated(null);
                  setSelected(null);
                  setAnswers((a) => [
                    ...a,
                    {
                      question: data,
                      correct: evaluated.success,
                    },
                  ]);
                } else {
                  const isCorrect = selected === correctAnswer;
                  setEvaluated({
                    success: isCorrect,
                  });
                }
                if (fillRef.current) fillRef.current.value = "";

                setShowNeedHelp(false);
                setShowStars(false);
              }}
              disabled={!selected}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithModal(PlayGame);

type ResultsProps = {
  answers: Answer[];
  limit: number;
};

const Results = ({ answers, limit }: ResultsProps) => {
  const modal = useModal();
  const correctAnswers = getCorrectAnswers(answers);
  const incorrectAnswers = getIncorrectAnswers(answers);
  const { status, correctAnswersPercentage } = getResults(
    correctAnswers,
    limit
  );

  return (
    <div className="safe-area-pt safe-area-pb">
      <Container className="text-center">
        {status.great ? (
          <Lottie animationData={trophyWinner} className="inline-block w-44" />
        ) : status.ok ? (
          <Lottie animationData={thumbsUp} className="inline-block w-44" />
        ) : (
          <Lottie animationData={sadLook} className="inline-block w-32" />
        )}

        {status.great && (
          <Lottie
            animationData={confettiFullScreen}
            className="z-2 absolute w-full left-0 top-0"
            loop={false}
          />
        )}

        <h1
          className={cc([
            "my-6 bg-gray-100 py-4 shadow-lg border-2",
            {
              "text-green-500": status.great,
              "text-red-500 border-red-200": status.bad,
            },
          ])}
        >
          <div>Scored</div>
          <div className="text-3xl">
            {Math.round(correctAnswersPercentage)}%
          </div>
        </h1>

        <div className="py-8">
          <div className="flex items-center justify-center text-green-500 text-center">
            <span className="font-bold text-2xl pr-2">
              {correctAnswers.length}
            </span>
            Correct Answer{correctAnswers.length > 1 ? "s" : ""}
          </div>
          <div className="flex items-center justify-center text-red-500 text-center">
            <span className="font-bold text-2xl pr-2">
              {incorrectAnswers.length}
            </span>
            Incorrect Answer{correctAnswers.length > 1 ? "s" : ""}
          </div>
        </div>

        <div className="text-center pt-8 space-y-4">
          <Button to="/" $fluid>
            Continue
          </Button>
          <Button
            $type="tertiary"
            $fluid
            onClick={() => {
              modal.open(<Feedback $type="results" />);
            }}
          >
            Feedback
          </Button>
        </div>
      </Container>
    </div>
  );
};

const LoadingQuestions = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 1000 * 5);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="p-4 text-center">
      <div>Loading...</div>

      {showButton && (
        <>
          <hr className="my-4" />

          <div className="text-sm p-2">
            Looks like we are having trouble to load the player...
          </div>

          <Button to="/">Go Back</Button>
        </>
      )}
    </div>
  );
};
