import cc from "classcat";

type Props = {
  children: any;
  className?: string;
};

const Container = ({ children, className }: Props) => {
  return <div className={cc(["p-3", className])}>{children}</div>;
};

export default Container;
