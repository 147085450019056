export default function getLeftTrialQuizzes(left: number) {
  switch (true) {
    case left >= 200:
      return 20;
    case left >= 150 && left < 200:
      return 15;
    case left >= 100 && left < 150:
      return 10;
    case left >= 50 && left < 100:
      return 5;
    case left >= 40 && left < 50:
      return 4;
    case left >= 30 && left < 40:
      return 3;
    case left >= 20 && left < 30:
      return 2;
    case left >= 10 && left < 20:
      return 1;
    default:
      return 0;
  }
}
