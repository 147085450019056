import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import "theme/global.css";
import "tailwindcss/tailwind.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Subscribe from "./pages/Subscribe";
import SelectLimit from "./pages/SelectLimit";
import PlayGame from "pages/PlayGame";
import Admin from "pages/Admin";
import AuthProvider, { useUser } from "components/AuthProvider";
import { FirestoreProvider } from "@react-firebase/firestore";
import config from "config";
import firebase from "firebase/app";
// import Checkout from "pages/Checkout";
import Account from "pages/Account";
import { useEffect } from "react";
import { updateRawQuestions } from "storage/collection";
import Clients from "pages/Clients";
import useInAppSubscription, {
  InAppPurchaseProvider,
} from "hooks/useInAppSubscription";
import { capitalize } from "lodash";
import SelectGameMode from "pages/SelectGameMode";
import { getDeviceInfo } from "utils/device";

const App = () => {
  return (
    <AuthProvider>
      <FirestoreProvider {...config.firebase} firebase={firebase}>
        <InAppPurchaseProvider>
          <AppContent />
        </InAppPurchaseProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};

export default App;

const AppContent = () => {
  const { loading } = useInAppSubscription();

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLED !== "true") {
      firebase.analytics().setAnalyticsCollectionEnabled(false);
    }
  }, []);

  useEffect(() => {
    getDeviceInfo().then(({ platform, version }) => {
      if (platform && version && version !== "N/A") {
        const currentVersion = parseInt(version.toString(), 10);

        firebase
          .firestore()
          .collection("app")
          .doc("version")
          .onSnapshot((snapshot) => {
            const data = snapshot.data();

            if (data?.[platform]) {
              const dataPlatform =
                typeof data[platform] === "string"
                  ? parseInt(data[platform].replace(".", ""), 10)
                  : data[platform];

              if (dataPlatform > currentVersion) {
                alert(
                  `There's a new update v${data[platform]} available. Please update to the latest version to avoid any problems.`
                );
              }
            }
          });
      }
    });
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>

      {loading && (
        <div className="fixed flex items-center justify-center inset-0 p-4 bg-white bg-opacity-80 backdrop-filter backdrop-blur">
          {capitalize(loading)}...
        </div>
      )}
    </>
  );
};

const refreshContent = async () => {
  const url = await firebase
    .storage()
    .ref()
    .child("practice.json")
    .getDownloadURL();

  const response: any = await fetch(url).then((r) => r.json());

  updateRawQuestions(response);
};

const Routes = () => {
  const { isSignedIn } = useUser();
  const location = useLocation();

  useEffect(() => {
    if (isSignedIn) {
      refreshContent().then().catch(console.error);
    }
  }, [isSignedIn]);

  useEffect(() => {
    firebase.analytics().setCurrentScreen(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Route path={"/select-mode"}>
        <SelectGameMode />
      </Route>
      <Route path="/practice/:mode/:limit" exact>
        <PlayGame />
      </Route>
      <Route path="/practice/:mode" exact>
        <SelectLimit />
      </Route>
      <Route path="/subscribe/clients" exact>
        <Clients />
      </Route>
      <Route path="/subscribe" exact>
        <Subscribe />
      </Route>
      <Route path="/account">
        <Account />
      </Route>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route exact path="/">
        {/* <Redirect to="/home" /> */}
        <Home />
      </Route>
    </>
  );
};
