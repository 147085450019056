import { useState } from "react";
import Button from "components/Button";
import { useModal } from "components/Modal";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Question } from "storage/collection";
import { withStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import ServerError from "components/ServerError";
import { CONTACT_EMAIL } from "config";
import firebase from "firebase/app";

type Props = {
  $type: "answer" | "results";
  answer?: string;
  question?: Question;
};

const Feedback = ({ $type, question, answer }: Props) => {
  const modal = useModal();
  const { currentUser } = firebase.app().auth();
  const [sent, setSent] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState<null | string>(null);

  if (sent) {
    return (
      <div className="px-4 py-8 text-center">
        <div className="pb-6 text-aqua">
          Thank you for your feedback, we will get back to you soon!
        </div>
        <Button
          $fluid
          onClick={() => {
            modal.close();
          }}
        >
          Close
        </Button>
      </div>
    );
  }

  return (
    <form
      className="p-4 space-y-8"
      onSubmit={async (e) => {
        e.preventDefault();

        const fullName = currentUser
          ? currentUser.displayName
          : e.currentTarget.fullName.value;
        const email = currentUser
          ? currentUser.email
          : e.currentTarget.email.value;
        const type = $type === "answer" ? e.currentTarget.type.value : "N/A";
        const comments =
          $type === "results"
            ? e.currentTarget.comments.value
            : [
                `Statement: ${question?.statement}`,
                `Options: ${question?.options}`,
                `Correct Answer: ${question?.correctAnswer}`,
                `User's answer: ${answer}`,
                `Used for trial: ${question?.forTrial}`,
              ].join("\n");
        const data = {
          from: $type,
          fullName,
          email,
          type,
          comments,
          date: new Date(),
          timestamp: Date.now(),
        };

        try {
          await firebase.firestore().collection("/feedback").doc().set(data);

          setSent(true);
        } catch (error: any) {
          console.error(error);
          setError(
            `We couldn't communicate with our servers. Please try again and contact us at ${CONTACT_EMAIL}`
          );
        }
      }}
    >
      <div className="text-center">Feedback</div>
      {!currentUser && (
        <TextField
          className="w-full"
          label="Your Name"
          required
          name="fullName"
        />
      )}
      {!currentUser && (
        <TextField
          className="w-full"
          label="Your Email"
          required
          type="email"
          name="email"
        />
      )}
      {$type === "answer" ? (
        <RadioGroup
          name="reason"
          value={reason}
          onChange={(event) => {
            setReason(event.target.value);
          }}
        >
          <FormControlLabel
            value="type"
            control={<CustomRadio />}
            label="Typo"
            name="type"
          />
          <FormControlLabel
            value="incorrect"
            control={<CustomRadio />}
            label="Incorrect Answer"
            name="type"
          />
        </RadioGroup>
      ) : (
        <TextField
          className="w-full"
          label="Comments"
          required
          multiline
          maxRows={4}
          name="comments"
        />
      )}

      {error && <ServerError>{error}</ServerError>}

      <div className="space-y-2">
        <Button $fluid>Send</Button>
        <Button
          $fluid
          $type="secondary"
          type="button"
          onClick={() => {
            modal.close();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Feedback;

const CustomRadio = withStyles({
  root: {
    color: blueGrey[400],
    "&$checked": {
      color: blueGrey[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
