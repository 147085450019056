import { App as CapacitorApp } from "@capacitor/app";
import { Device } from "@capacitor/device";

export const getRelease = async (): Promise<"N/A" | string> => {
  try {
    const info = await CapacitorApp.getInfo();

    return info.version;
  } catch (err) {
    console.error("APP INFO ERROR", err);
  }

  return "N/A";
};

export const getDeviceInfo = async () => {
  try {
    const info = await CapacitorApp.getInfo();
    const device = await Device.getInfo();
    const langCode = await Device.getLanguageCode();

    return {
      version: info.version,
      platform: device.platform,
      model: device.model,
      osVersion: device.osVersion,
      langCode: langCode.value,
    };
  } catch (err) {
    console.error("DEVICE INFO ERROR", err);
  }

  return {};
};
