import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import isBrowser from "utils/isBrowser";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getLocalPlays } from "storage/user.storage";
import {
  getCorrectQuestions,
  getIncorrectQuestions,
  getPaidUnseenQuestions,
  getTrialUnseenQuestions,
  getUnseenQuestions,
} from "storage/collection";
import { getDeviceInfo, getRelease } from "utils/device";

import "./index.css";

const initSentry = async () => {
  const device = await getDeviceInfo();

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: await getRelease(),
      environment: process.env.NODE_ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      beforeSend(event) {
        event.extra = {
          ...event.extra,
          device,
          localStorage: {
            plays: getLocalPlays(true),
            paidUnseenQuestions: getPaidUnseenQuestions().length,
            trialUnseenQuestions: getTrialUnseenQuestions().length,
            unseenQuestions: getUnseenQuestions().length,
            correctQuestions: getCorrectQuestions().length,
            incorrectQuestions: getIncorrectQuestions().length,
          },
        };

        return event;
      },
    });
  } else {
    console.debug(
      "process.env.REACT_APP_SENTRY_DSN",
      !!process.env.REACT_APP_SENTRY_DSN
    );
    console.debug(
      "process.env.REACT_APP_SENTRY_RELEASE",
      !!process.env.REACT_APP_SENTRY_RELEASE
    );
  }
};

const render = async () => {
  console.debug("render");

  await initSentry();

  console.debug("React Render");

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
};

if (isBrowser()) {
  render();
} else {
  document.addEventListener(
    "deviceready",
    () => {
      console.debug("device ready");
      render();

      if (process.env.REACT_APP_SMARTLOCK_API_KEY && Smartlook) {
        try {
          Smartlook.setupAndStartRecording(
            {
              smartlookAPIKey: process.env.REACT_APP_SMARTLOCK_API_KEY,
            },
            () => {
              console.log("Smartlock is recording...");
            },
            (error) => {
              Sentry.captureMessage(`Smartlock init`, {
                extra: {
                  error,
                },
                level: Sentry.Severity.Error,
              });
            }
          );
        } catch (error) {
          console.error("Smartlook", error);
        }
      } else {
        console.log("Smartlook is not available");
      }
    },
    false
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
