import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Container from "components/Container";
import Button from "components/Button";
import cc from "classcat";
import { getIncorrectQuestions } from "storage/collection";
import { settings } from "config";
import { useUser } from "components/AuthProvider";
import { usePlay } from "storage/user.storage";
import GoBackButton from "components/GoBackButton";

const SelectLimit = () => {
  const history = useHistory();
  const location = useLocation();
  const mode = new URLSearchParams(location.search).get("mode") || "new";
  const user = useUser();
  const play = usePlay();
  const { mode: gameMode } = useParams<{ mode: string }>();

  if (mode === "incorrect" && getIncorrectQuestions().length < 20) {
    return <Redirect to="/" />;
  }

  const BUNDLES = [10, 20, 30].filter((bundle) => {
    return (
      mode !== "incorrect" ||
      (mode === "incorrect" && getIncorrectQuestions().length >= bundle)
    );
  });

  return (
    <div className="h-full flex flex-col justify-center">
      <div className="p-3">
        <GoBackButton route="/select-mode" />
      </div>
      <div className="h-full flex items-center safe-area-pt safe-area-pb relative">
        <Container className="w-full text-center">
          {mode === "incorrect" && (
            <div className="text-red-400">Play with your Incorrect Answers</div>
          )}
          <h1 className="text-center py-8 text-aqua text-lg uppercase">
            Choose your quiz length
          </h1>

          <ul>
            {BUNDLES.map((bundle, i) => {
              const isAvailable =
                user.status === "member" ||
                (settings.TRIAL_AVAILABLE_BUNDLES.includes(bundle) &&
                  play.plays().left !== 0);

              return (
                <li key={`key-${bundle}`} className="p-2 text-center">
                  <Link
                    className={cc([
                      "inline-flex items-center justify-center text-white p-2 w-full h-20 rounded-full text-2xl",
                      {
                        "bg-aqua bg-opacity-80": isAvailable && bundle === 10,
                        "bg-dark-aqua bg-opacity-80":
                          isAvailable && bundle === 20,
                        "bg-dark-aqua": isAvailable && bundle === 30,
                        "bg-gray-300": !isAvailable,
                      },
                    ])}
                    to={`/practice/${gameMode}/${bundle}${
                      mode === "multiple-choice" || mode === "fill-gap"
                        ? `?mode=${mode}`
                        : ""
                    }`}
                    onClick={(e) => {
                      if (!isAvailable) {
                        e.preventDefault();
                      }
                    }}
                  >
                    {bundle}
                  </Link>
                </li>
              );
            })}
          </ul>

          <Button
            $type="tertiary"
            onClick={() => {
              history.goBack();
            }}
          >
            go back
          </Button>
        </Container>
      </div>
    </div>
  );
};

export default SelectLimit;
