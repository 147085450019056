import Button from "components/Button";
import React from "react";

function Tip({
  children,
  toggle,
  bgColor,
}: {
  children: string | React.ReactElement;
  toggle: () => void;
  bgColor: string;
}) {
  return (
    <div
      className="absolute w-full h-full z-20 text-white p-4 text-center flex flex-col justify-center gap-8 text-lg"
      style={{ backgroundColor: bgColor }}
    >
      <div>{children}</div>
      <Button onClick={() => toggle()} $fluid className="rounded-md p-3">
        Continue
      </Button>
    </div>
  );
}

Tip.defaultProps = {
  bgColor: "#333",
};

export default Tip;
