import { IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { FirestoreCollection } from "@react-firebase/firestore";
import Container from "components/Container";
import Login from "components/Login";
import Button from "components/Button";
import { zip } from "lodash/fp";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import firebase from "firebase/app";
import { CONTACT_EMAIL } from "config";
import Lottie from "lottie-react";
import thankYouLottie from "assets/lottie/11405-thank-you.json";
import Logo from "components/Logo";
import { useModal, WithModal } from "components/Modal";
import BecomeAClient from "components/BecomeAClient";
import useIsClient from "hooks/useIsClient";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";

const Clients = () => {
  return (
    <div className="safe-area-pt safe-area-pb">
      <Container>
        <Button to="/">
          <IonIcon icon={arrowBackOutline} className="block" />
        </Button>

        <IfFirebaseAuthed>
          {() => (
            <div className="pt-4">
              <FindClient />
            </div>
          )}
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          {() => <Login defaultScreen="signup" />}
        </IfFirebaseUnAuthed>
      </Container>
    </div>
  );
};

export default Clients;

const FindClient = () => {
  const { loading, isClient } = useIsClient();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isClient) {
    return <ForClients />;
  }

  return <ForNonClients />;
};

const ForClients = () => {
  const name = firebase.auth().currentUser?.displayName;
  const buttonText = "Register Here";

  return (
    <div className="text-center">
      <div className="space-y-2">
        <div className="">
          <p className="text-aqua text-lg">
            {name && <strong>Hello {name.trim()}.</strong>}
          </p>

          <p>Thank you for being a client.</p>

          <p>
            You now have a <strong>FREE lifetime subscription</strong> to this
            App.
          </p>

          <p>
            Please enjoy using it and I would appreciate any feeedback you have
            to offer.
          </p>
        </div>

        <p>
          After using it for a few weeks, please take the time to write a
          review.{" "}
        </p>
        <div className="text-sm bg-white p-2 shadow-lg">
          <p>
            <Lottie className="" animationData={thankYouLottie} />
          </p>

          <p>
            I can't do this without you.{" "}
            <strong>I think you're the BEST!</strong> - Love, Hayley
          </p>
        </div>
      </div>

      <div className="pt-6">
        Please use the coupon <strong>FREE90</strong>
      </div>

      <div className="pt-4">
        <FirestoreCollection
          path="/products"
          where={{
            field: "active",
            operator: "==",
            value: true,
          }}
        >
          {(productsState) => {
            if (productsState.isLoading) {
              return <div>Loading...</div>;
            }

            // single plan
            const productID = productsState.ids?.[0];
            const plan = productsState.value?.[0];

            if (!productID || !plan) {
              return (
                <Button
                  $fluid
                  onClick={() => {
                    alert(`Please contact us at ${CONTACT_EMAIL}`);
                  }}
                >
                  {buttonText}
                </Button>
              );
            }

            return (
              <FirestoreCollection path={`/products/${productID}/prices`}>
                {(pricesState) => {
                  if (pricesState.isLoading) {
                    return <div>Loading...</div>;
                  }

                  const price = (
                    zip(pricesState.ids, pricesState.value) as PriceIDTuple[]
                  ).find(([, price]) => !!price.metadata.clientsOnly);

                  if (!price) {
                    return (
                      <Button
                        $fluid
                        onClick={() => {
                          alert(`Please contact us at ${CONTACT_EMAIL}`);
                        }}
                      >
                        {buttonText}
                      </Button>
                    );
                  }

                  return (
                    <>
                      <Button
                        $fluid
                        onClick={() => {
                          const id = InAppPurchase2.get("monthly")?.id;

                          if (id) {
                            InAppPurchase2.order(id);
                          } else {
                            alert(
                              `Something went wrong. Please contact support at ${CONTACT_EMAIL}.`
                            );
                          }
                        }}
                      >
                        {buttonText}
                      </Button>

                      <div className="text-center text-xs pt-2">
                        By subscribing, you accept our
                        <br />
                        <a
                          href="https://www.termsfeed.com/live/77df1f90-fb01-4e06-9f30-6be80f95db9e"
                          target="_blank"
                          rel="noreferrer"
                          className="text-aqua underline"
                        >
                          Terms {`&`} Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.termsfeed.com/live/c7390429-bb18-4247-b267-797e3ef3a6d9"
                          target="_blank"
                          rel="noreferrer"
                          className="text-aqua underline"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </>
                  );
                }}
              </FirestoreCollection>
            );
          }}
        </FirestoreCollection>
      </div>
    </div>
  );
};

const ForNonClients = WithModal(() => {
  const modal = useModal();

  return (
    <>
      <div className="text-center leading-7">
        You are not a personal client of{" "}
        <strong>H.E.A.R.T., Hayley's English Advanced Rapid Training</strong>.
        <br />
        For information on private "Perfect your English" lessons,{" "}
        <Button
          $size="sm"
          onClick={() => {
            modal.open(<BecomeAClient />);
          }}
        >
          contact
        </Button>{" "}
        us.
      </div>

      <div className="pt-16">
        <Logo $withHEART $type="english" />
      </div>
    </>
  );
});
