import firebase from "firebase/app";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";
import { CSSTransition } from "react-transition-group";
import styles from "./index.module.scss";
import config from "config";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
  SetStateAction,
} from "react";
import { IAPProduct } from "@ionic-native/in-app-purchase-2";
import * as Sentry from "@sentry/react";

const Context = createContext<{
  isLoaded: boolean;
  isSignedIn: boolean;
  status: "member" | "non-member" | "anonymous";
  data: firebase.User | null;
  activeSubscription: null | IAPProduct;
  setActiveSubscription: Dispatch<SetStateAction<null | IAPProduct>>;
}>({
  isLoaded: false,
  isSignedIn: false,
  status: "anonymous",
  data: null,
  activeSubscription: null,
  setActiveSubscription: () => false,
});

export const useUser = () => useContext(Context);

const AuthStateProvider = ({ isLoaded, isSignedIn, children }: any) => {
  const [loading, setLoading] = useState(true);
  const [activeSubscription, setActiveSubscription] =
    useState<null | IAPProduct>(null);

  useEffect(() => {
    firebase.analytics().setUserId("anonymous");
    firebase.analytics().setUserProperties({});
    Sentry.setUser(null);

    if (isLoaded) {
      if (isSignedIn) {
        setLoading(true);

        const currentUser = getCurrentUser()!;

        const unsubscriber = firebase
          .firestore()
          .collection("customers")
          .doc(currentUser.uid)
          .collection("subscriptions")
          .where("status", "in", ["trialing", "active"])
          .onSnapshot(() => {
            // setActiveSubscription(snap.docs.length > 0);
            setLoading(false);
          });

        firebase.analytics().setUserId(currentUser.uid);
        firebase.analytics().setUserProperties({
          name: currentUser.displayName,
          email: currentUser.email,
          metadata: currentUser.metadata,
        });
        Sentry.setUser({
          id: currentUser.uid,
          email: currentUser.email || undefined,
          name: currentUser.displayName,
          metadata: currentUser.metadata,
        });

        Smartlook.setUserIdentifier(
          {
            identifier: currentUser.uid,
            sessionProperties: {
              name: currentUser.displayName,
              email: currentUser.email,
            },
          },
          () => {},
          () => {}
        );

        return () => unsubscriber();
      } else {
        setLoading(false);
      }
    }
  }, [isLoaded, isSignedIn]);

  return (
    <>
      {!loading && (
        <Context.Provider
          value={{
            isLoaded,
            isSignedIn,
            status: isSignedIn
              ? activeSubscription
                ? "member"
                : "non-member"
              : "anonymous",
            data: getCurrentUser(),
            activeSubscription,
            setActiveSubscription,
          }}
        >
          {children}
        </Context.Provider>
      )}
      <CSSTransition
        in={loading}
        timeout={500}
        classNames={{ ...styles }}
        unmountOnExit
      >
        <div className="fixed inset-0 flex items-center justify-center">
          Loading...
        </div>
      </CSSTransition>
    </>
  );
};

type Props = {
  children: any;
};

const AuthProvider = ({ children }: Props) => {
  return (
    <FirebaseAuthProvider {...config.firebase} firebase={firebase}>
      <FirebaseAuthConsumer>
        {({ firebase, isSignedIn }) => {
          const isLoaded = firebase.apps && firebase.apps.length > 0;

          return (
            <AuthStateProvider isLoaded={isLoaded} isSignedIn={isSignedIn}>
              {children}
            </AuthStateProvider>
          );
        }}
      </FirebaseAuthConsumer>
    </FirebaseAuthProvider>
  );
};

export default AuthProvider;

export const login = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const signup = (email: string, password: string, name: string) =>
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(({ user }) =>
      user?.updateProfile({
        displayName: name,
      })
    );

export const logout = () => {
  firebase.auth().signOut();
};

export const forgotPassword = (email: string) =>
  firebase.auth().sendPasswordResetEmail(email);

export const getCurrentUser = () => firebase.auth().currentUser;

export const isLoggedIn = () => !!getCurrentUser();
