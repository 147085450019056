import React from "react";
import { useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import Button from "components/Button";

function GoBackButton({ route }: { route?: string }) {
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        route ? history.push(route) : history.goBack();
      }}
      className="block "
    >
      <IonIcon icon={arrowBackOutline} className="block" />
    </Button>
  );
}

export default GoBackButton;
